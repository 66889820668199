import type {
  DeepDocDuplicateDecision,
  DeepDocJobWorkflowStatus,
  DeepDocPageSection,
  DeepDocRecordPageRange,
  DeepdocBoundaryReviewableField,
  SliceStatus
} from 'lib/graphql/__generated__/graphql';
import apolloClient from 'lib/graphql/apolloClient';
import {
  CREATE_DEEPDOC_JOB_BOUNDARY_MUTATION,
  CREATE_DEEPDOC_JOB_RECORD_DUPLICATE_ENTRY,
  MARK_DEEP_DOC_RECORD_BOUNDARY_AS_DUPLICATE_MUTATION,
  MARK_DEEP_DOC_RECORD_BOUNDARY_FIELD_AS_REVIEWED_MUTATION,
  MOVE_DEEP_DOC_PAGES_TO_SECTION_MUTATION,
  SAVE_CLIENT_RESULT,
  UPDATE_DEEP_DOC_RECORD_BOUNDARY_FIELD_MUTATION,
  UPDATE_DEEP_DOC_RECORD_BOUNDARY_MUTATION,
  UPDATE_DEEP_DOC_RECORD_STATUS_MUTATION,
  UPDATE_SLICE_STATUS_MUTATION
} from 'lib/graphql/mutations/deepdoc.mutation';
import {
  GET_DEEPDOC_JOB_BOUNDARIES_BY_ID,
  GET_DEEPDOC_JOB_PAGE_BOUNDARIES_BY_ID,
  GET_DEEPDOC_JOB_TOC_BY_ID,
  GET_POTENTIAL_DUPLICATE_BOUNDARIES,
  GET_SUMMARY_DEEPDOC_JOB_PAGE_BOUNDARIES_BY_ID
} from 'lib/graphql/queries/deepdoc.query';

export const getDeepdocJobRecords = async (jobId: string) => {
  const recordData = await apolloClient.query({
    query: GET_DEEPDOC_JOB_PAGE_BOUNDARIES_BY_ID,
    variables: { id: jobId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const getSummaryDeepdocJobRecords = async (jobId: string) => {
  const recordData = await apolloClient.query({
    query: GET_SUMMARY_DEEPDOC_JOB_PAGE_BOUNDARIES_BY_ID,
    variables: { id: jobId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const getDeepdocJobTOC = async (jobId: string) => {
  const recordData = await apolloClient.query({
    query: GET_DEEPDOC_JOB_TOC_BY_ID,
    variables: { id: jobId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const getDeepdocJobBoundaries = async (jobId: string) => {
  const recordData = await apolloClient.query({
    query: GET_DEEPDOC_JOB_BOUNDARIES_BY_ID,
    variables: { id: jobId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const createDeepdocJobBoundary = async (
  jobId: string,
  pages: DeepDocRecordPageRange[],
  type: string
) => {
  const recordData = await apolloClient.mutate({
    mutation: CREATE_DEEPDOC_JOB_BOUNDARY_MUTATION,
    variables: { jobId, pages, type },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const updateDeepDocRecordBoundaryEntry = async (
  completed: boolean,
  dateOfService: string,
  facility: string,
  id: string,
  physician: string,
  type: string,
  pages?: DeepDocRecordPageRange[],
  extractionReviewed?: boolean
) => {
  const recordData = await apolloClient.mutate({
    mutation: UPDATE_DEEP_DOC_RECORD_BOUNDARY_MUTATION,
    variables: {
      completed,
      extractionReviewed,
      dateOfService,
      facility,
      id,
      physician,
      pages,
      type
    },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const updateDeepDocRecordStatus = async (
  id: string,
  newStatus: DeepDocJobWorkflowStatus
) => {
  const recordData = await apolloClient.mutate({
    mutation: UPDATE_DEEP_DOC_RECORD_STATUS_MUTATION,
    variables: { id, newStatus },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const updateDeepDocRecordBoundaryField = async (
  id: string,
  field: DeepdocBoundaryReviewableField,
  value: string
) => {
  const recordData = await apolloClient.mutate({
    mutation: UPDATE_DEEP_DOC_RECORD_BOUNDARY_FIELD_MUTATION,
    variables: { id, field, value },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const markDeepDocRecordBoundaryFieldAsReviewed = async (
  id: string,
  field: DeepdocBoundaryReviewableField
) => {
  const recordData = await apolloClient.mutate({
    mutation: MARK_DEEP_DOC_RECORD_BOUNDARY_FIELD_AS_REVIEWED_MUTATION,
    variables: { id, field },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const getPotentialDuplicateBoundaries = async (id: string) => {
  const recordData = await apolloClient.query({
    query: GET_POTENTIAL_DUPLICATE_BOUNDARIES,
    variables: { id },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const markDeepDocRecordBoundaryAsDuplicate = async (
  decision: DeepDocDuplicateDecision,
  duplicateId: string
) => {
  const recordData = await apolloClient.mutate({
    mutation: MARK_DEEP_DOC_RECORD_BOUNDARY_AS_DUPLICATE_MUTATION,
    variables: { decision, duplicateId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const moveDeepDocPagesToSection = async (
  deepDocJob: string,
  pages: string[],
  section: DeepDocPageSection
) => {
  const recordData = await apolloClient.mutate({
    mutation: MOVE_DEEP_DOC_PAGES_TO_SECTION_MUTATION,
    variables: { deepDocJob, pages, section },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const createDeepDocJobRecordDuplicateEntry = async (
  deepdocJobId: string,
  boundaryA: string,
  boundaryB: string
) => {
  const recordData = await apolloClient.mutate({
    mutation: CREATE_DEEPDOC_JOB_RECORD_DUPLICATE_ENTRY,
    variables: { boundaryA, boundaryB, deepdocJobId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const updateSliceStatus = async (id: string, newStatus: SliceStatus) => {
  const recordData = await apolloClient.mutate({
    mutation: UPDATE_SLICE_STATUS_MUTATION,
    variables: { id, newStatus },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const saveClientResult = async (jobId: string, clientResult: string) => {
  const recordData = await apolloClient.mutate({
    mutation: SAVE_CLIENT_RESULT,
    variables: { jobId, clientResult },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};
